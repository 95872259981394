.portfolioImg {
    padding: 1vmin;
    max-width: 25vmin;
    width: 25vmin;
}

@media (max-width: 1200px) {
    h2, h3 {
        text-align: center;
    }
    .imgContainer {
        display: none;
    }
    .portfolioItem {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}