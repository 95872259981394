body {
  margin: 0;
  font-family: "DM Sans", -apple-system, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  margin-top: 1rem;
}

a, .nav-link {
  color: #317EC4;
  cursor: pointer;
}