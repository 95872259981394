.portfolioIntro {
  font-weight: 400;
  font-size: 4.5vmin;
  width: fit-content;
  padding: 2vmin;
}

.navFont {
  font-size: 5vmin;
  color: #317ec4;
}

.portfolioIntro a {
  text-decoration: none;
}

.portfolioStart a {
  padding: 1vmin;
}

.portfolioStart a:hover {
  border-radius: 5%;
  background-color: #CCC;
  width: fit-content;
  margin: 0 2vmin;
  transition: all 1s ease-in-out;
}

@media (max-width: 1200px) {
  .porfolioIntro {
    font-size: 32px;
  }
}
